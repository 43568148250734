import fetch from 'auth/FetchInterceptor'

const whitelabelService = {}

whitelabelService.getWhitelabelUsers = function () {
    return fetch({
        url: '/whitelabel/users',
        method: 'get'
    })
}

whitelabelService.addWhiteLabelUser = function (data) {
    return fetch({
        url: '/whitelabel/user',
        method: 'post',
        data
    })
}

whitelabelService.updateWhiteLabelUser = function (data) {
    return fetch({
        url: '/whitelabel/user',
        method: 'put',
        data
    })
}

whitelabelService.deleteWhiteLabelUser = function (id) {
    return fetch({
        url: `/whitelabel/user/${id}`,
        method: 'delete'
    })
}
 
whitelabelService.changeWhiteLabelUserPassword = function (id, newPassword) {
    return fetch({
        url: `/whitelabel/user/${id}/change-password`,
        method: 'put',
        data: { newPassword }
    })
}

whitelabelService.changeWhiteLabelUserPlan = function (id, newPlanIds) {
    return fetch({
      url: `/whitelabel/user/${id}/change-plan`,
      method: 'put',
      data: { newPlanIds }
    })
  }
  


whitelabelService.addWhiteLabelSettinngs = function (data) {
    return fetch({
        url: '/whitelabel/settings',
        method: 'post',
        data
    })
}

whitelabelService.getWhiteLabelSettings = function () {
    return fetch({
        url: '/whitelabel/settings',
        method: 'get'
    })
}

whitelabelService.getDataFromDomain = function (domain) {
    return fetch({
        url: `/search-by-domain`,
        method: 'post',
        data: domain
    })
}


whitelabelService.getSmtpSettings = function () {
    return fetch({
        url: '/whitelabel/smtp',
        method: 'get'
    })
}

whitelabelService.createSmtpSettings = function (data) {
    return fetch({
        url: '/whitelabel/smtp',
        method: 'post',
        data
    })
}

whitelabelService.sendTestEmail = function (data) {
    return fetch({
        url: '/whitelabel/smtptest',
        method: 'post',
        data
    })
}


export default whitelabelService