import React,{useState,useEffect} from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import { UserProvider } from 'context/UserContext';
import './lang'
import whitelabelService from 'services/WhitelabelService';
import WhiteLabelContext from 'context/WhitelabelContext';
import { getDataFromDomain } from 'utils/api/whitelabel';
import { Spin } from 'antd';



const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};





function App() {
  const [whiteLabelData, setWhiteLabelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const currentDomain = window.location.host;
    const isOurDomain = currentDomain.includes("clicksites.ai") || currentDomain.includes("localhost");
    const sessionWhiteLabelData = sessionStorage.getItem('whiteLabelData');

    if (!isOurDomain) {
      if (sessionWhiteLabelData) {
        // Use white label data from session storage
        setWhiteLabelData(JSON.parse(sessionWhiteLabelData));
        updatePageTitleAndFavicon(JSON.parse(sessionWhiteLabelData));
        setIsLoading(false);
      } else {
        setIsLoading(true);
        getDataFromDomain({ domain_name: currentDomain })
          .then((resp) => {
            const whiteLabel = resp;
            if (whiteLabel) {
              whiteLabel.domain = currentDomain;
              setWhiteLabelData(whiteLabel);
              updatePageTitleAndFavicon(whiteLabel);
              sessionStorage.setItem('whiteLabelData', JSON.stringify(whiteLabel)); // Store in session storage
            }
            setIsLoading(false);
          })
          .catch(() => {
            document.title = "Sight Builder";
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  

  const updatePageTitleAndFavicon = (whiteLabel) => {
    document.title = whiteLabel.brand_name;
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = whiteLabel.brand_favicon;
    document.head.appendChild(link);
  };

  if (isLoading) {
    return <div><Spin/></div>; // or any other loading indicator
  }


  return (
    <WhiteLabelContext.Provider value={whiteLabelData}>
    <div className="App">
      <UserProvider>
        <Provider store={store}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </Provider>
      </UserProvider>
    </div>
    </WhiteLabelContext.Provider>
  );
}

export default App;
